/* eslint-disable camelcase */
export default class Accessory {
  alias;
  brand;
  category_id;
  description;
  description_socials;
  gallery;
  id;
  library_id;
  name;
  nickname;
  published;
  type;
  seo_indexing;
  seo_title;

  constructor() {
    this.alias = '';
    this.brand = [];
    this.category_id = null;
    this.description = '';
    this.description_socials = '';
    this.gallery = [];
    this.id = null;
    this.library_id = null;
    this.name = '';
    this. nickname = '';
    this.published = 0;
    this.type = 'accessory';
    this.seo_indexing = null;
    this.seo_title = '';
  }
}
