<script>
import accessoriesService from '@/services/accessories.service'
import brandsService from '@/services/brands.service'
import libraryService from '@/services/library.service'

import Accessory from '@/data/models/Accessory'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'
import Modal from '@/components/Modal'
import LibraryList from '@/components/LibraryList'

import Draggable from 'vuedraggable'

export default {
  name: 'SingleAccessorie',
  components: {
    EditLayout,
    Modal,
    LibraryList,
    Draggable
  },
  beforeRouteLeave(to, from, next) {
    if(this.hasChanges) {
      this.$notify({
        message: this.$t('unsavedChanges'),
        icon: "far fa-bell",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      })
    }else {
      next()
    }
  },
  data() {
    return {
      item: new Accessory,
      categories: [],
      brands: [],
      photo: {},
      gallery: [],
      tempGallery: [],
      isGalleryModalOpened: false,
      indexing,
      isLoaded: false,
      hasChanges: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.hasChanges = this.isLoaded
        this.hasChanges && this._preventWindowClose()
        this.isLoaded = true
      }
    }
  },
	mounted() {
    !this.isNew && this.id && this._get()
    this._getCategories()
    this._getBrands()
  },
  methods: {
    _get() {
      accessoriesService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
          this.gallery = res.data.gallery || []
        })
    },
    _getCategories() {
      accessoriesService.getCategories()
        .then(res => {
          this.categories = res.data
        })
    },
    _getBrands() {
      brandsService.getAll({})
        .then(res => {
          this.brands = res.data
        })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id: id })
        .then(res => {
          this.photo = res.data
        })
    },
    create() {
      accessoriesService.create({ data: this.item })
      .then(res => {
        this.hasChanges = false;
        this.$notify({
          message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('accessory') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
        this.$router.push({ name: 'accessories' })
      });
    },
    edit() {
      accessoriesService.edit({ data: this.item })
        .then(res => {
          this.hasChanges = false;
          window.onbeforeunload = null;
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('accessory') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        });
    },
    updateSelectedBrands(newVal) {
      this.item.brand = newVal
    },
    updateGallery(items) {
			this.tempGallery = items
		},
    confirmNewGallery() {
			if(this.tempGallery && this.tempGallery.length) {
        this.gallery = this.tempGallery
        this.tempGallery = []
        this.updateGalleryModel()
      }
			this.isGalleryModalOpened = false
    },
    updateGalleryModel() {
			this.item.gallery = this.gallery.map((item, i) => {
        return { id: item.id, position: i + 1 }
      })
    },
    _preventWindowClose() {
      window.onbeforeunload = this._confirmMessage
    },
    _confirmMessage() {
      return this.$t('unsavedChangesQuestion');
    },
  }
}
</script>

<template>
  <EditLayout
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="subtitle">
      <div class="row">
        <BaseInput
          v-model="item.nickname"
          class="col-md-6"
          :label="$t('nickname')"
          :placeholder="$t('enterNickname')"
        />
        <div class="col-md-6">
          <label>{{ $tc('category') }}</label>
          <select
            v-model="item.category_id"
            class="form-control"
          >
            <option
              v-for="category in categories"
              :key="category.id+'category'"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div slot="middle">
      <label>{{ $t('gallery') }}</label>
      <div class="gallery">
        <div v-if="!gallery.length">
          <BaseIcon
            v-for="index in 6"
            :key="index"
            icons-group="fas"
            icon="fa-image"
            size="xxl"
            class="placeholderIcons"
          />
        </div>
        <Draggable
          v-model="gallery"
          class="galleryInner"
          @change="updateGalleryModel"
        >
          <div
            v-for="galleryItem in gallery"
            :key="'gallery' + galleryItem.id"
            class="galleryItem"
          >
            <BaseImage
              :source="galleryItem"
              size="thumb"
            />
          </div>
        </Draggable>
        <BaseButton
          type="green"
          gradient
          @click="isGalleryModalOpened = true"
        >
          {{ $t('updateGallery') }}
        </BaseButton>
      </div>
      <Modal
        :show.sync="isGalleryModalOpened"
        :centered="true"
        :show-close="true"
      >
        <LibraryList
          v-if="isGalleryModalOpened"
          :limit="40"
          select-items="multiple"
          class="library"
          :preselected-items="gallery"
          fixed-type="photos"
          grid-item-size="100"
          @updateSelected="updateGallery"
        />
        <div class="libraryButton text-right">
          <BaseButton
            type="green"
            gradient
            @click="confirmNewGallery"
          >
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </Modal>
    </div>
    <!-- + SIDEBAR MIDDLE + -->
    <div slot="sidebar-middle">
      <div>
        <BaseMultiSelect
          :label="$tc('brand', 2)"
          :items="brands"
          :pre-selected-items="item.brand"
          @updateSelected="updateSelectedBrands"
        />
      </div>
    </div>
    <!-- SIDEBAR BOTTOM -->
    <div slot="sidebar-bottom">
      <div class="metaIndexling form-group">
        <label>{{ $t('metaIndexing') }}</label>
        <select
          v-model="item.seo_indexing"
          class="form-control"
        >
          <option :value="null">
            {{ $t('indexing') }}
          </option>
          <option
            v-for="index in indexing"
            :key="index"
            :value="index"
          >
            {{ index }}
          </option>
        </select>
      </div>
    </div>
  </EditLayout>
</template>

<style lang="scss" scoped>
@use '~@/assets/sass/design.sass' as *;

.gallery {
  &Inner {
    display: flex;
    margin-bottom: $base-spacing;
  }

  &Item {
    position: relative;
  }

  &Item~&Item {
    margin-left: $base-spacing;
  }
}

.library {
  max-height: 85vh;
  overflow: auto;

  &Button {
    padding: $base-spacing;
  }
}

.placeholderIcons~.placeholderIcons {
  margin-left: $base-spacing;
}
</style>
